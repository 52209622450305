<template>
	<div class="w-full h-full flex flex-col">
		<Header>
			<div>
				<h1>Meldinger</h1>
				<h3 v-if="messages">Totalt {{messages.length}} {{messages.length > 1 ? 'meldinger' : 'melding'}}</h3>
			</div>
		</Header>
		
		<div class="flex-grow overflow-auto p-6 md:p-12">
			<div 
				v-if="loading == 'fetching'"
				class="h-full flex items-center justify-center">
				
				<div class="spinner"></div>
			</div>
			<div v-else-if="messages?.length">
				<table>
					<thead>
						<tr>
							<th>
								Meldingstekst
							</th>
							<th>
								Mottakere
							</th>
							<th>
								Avsender
							</th>
							<th>
								Opprettet
							</th>
							<th>
								Endret
							</th>
						</tr>
					</thead>
					<tbody>
						<tr 
							v-for="message in messages"
							:key="'message_'+message.messageId"
							@click="messageEdit = message"
							class="cursor-default">
							
							<td>
								<div class="truncate max-w-md">
									{{ message.text }}
								</div>
								<!-- <div class="truncate max-w-md">
									<span class="truncate max-w-md">
										{{ message.text }}
									</span>
								</div> -->
							</td>
							<td>
								<div v-if="message.type == 'everyone'">Alle</div>
								<div v-if="message.type == 'departmentIds'">
									{{ departments?.length && departments.find( d => d.departmentId == message.departmentIds[0]) ? departments.find( d => d.departmentId == message.departmentIds[0]).name : 'avdeling' }}
									<span v-if="message.departmentIds.length > 1">
										+ {{ (message.departmentIds.length -1) }} {{ message.departmentIds.length == 2 ? 'avdeling' : 'avdelinger' }}
									</span>
								</div>
								<div v-if="message.type == 'usernames'">
									<span 
										v-for="(username, usernameIndex) in message.usernames.filter( (u,index) => index < 2)"
										:key="message.messageId+'_'+username">
										
										<span v-if="usernameIndex == 1">, </span>
										{{ usernameToName( username ) }}
									</span>
									
									<span v-if="message.usernames.length > 2">
										+ {{ message.usernames.length-2 }}  {{ (message.usernames.length-2) == 1 ? 'bruker' : 'brukere' }}
									</span>
								</div>
							</td>
							<td class="w-64">
								<div class="flex items-center">
									<!-- <div class="w-32">
										<img 
											v-if="message?.author?.picture"
											:src="fileKeyToUrl( message.author.picture )"
											class="rounded-full w-12 h-12 object-cover"
										/>
									</div> -->
									<div class="truncate">
										{{ usernameToName( message?.author?.username ) }}
									</div>
								</div>
							</td>
							<td class="w-48">
								{{ message.createdAt | moment('lll') }}
							</td>
							<td class="w-48">
								{{ message.updatedAt | moment('lll') }}
							</td>
							<!-- <td>
								<pre>{{ message }}</pre>
							</td> -->
						</tr>
					</tbody>
				</table>
			</div>
			<div 
				v-else
				class="flex items-center justify-center h-full">
				
				<div class="text-center max-w-xl">
					<p>
						Her kan du opprette meldinger du ønsker å sende til dine ansatte.<br>
						Dette gjør du ved å klikke på plusstegnet nede i høyre hjørne.
					</p>
				</div>
			</div>
		</div>
		
		<div class="absolute bottom-0 right-0 m-6">
			<button 
				@click="showMessageNew = true"
				class="button submit round">
				
				<i class="fas fa-plus" />
			</button>
		</div>
		
		<Modal 
			v-if="showMessageNew"
			@close="showMessageNew = false"
			insideClasses="bg-white">
			
			<header slot="header">
				Ny melding
			</header>
			
			<MessageEdit 
				:message="messageNew" 
				@close="showMessageNew = false"
			/>
		</Modal>
		
		 <div 
			v-if="messageEdit"
			class="absolute inset-0 flex">
			
			<div 
				@click="messageEdit = null"
				class="flex-grow bg-black opacity-25 ">
				
			</div>
			
			<transition name="fade">
				<MessageEdit 
					:message="messageEdit" 
					@close="messageEdit = null"
					:showHeading="true"
					class="
						absolute bg-white right-0 overflow-hidden w-full
						md:rounded-l-lg 
						md:w-150
						md:shadow-2xl
					"
				/>
			</transition>
		</div>
	</div>
</template>

<script>
	export default {
		components: {
			'MessageEdit': () => import('@/components/messages/MessageEdit.vue'),
		},
		
		computed:{
			messages(){
				return this.$store.getters.getMessages
			},
			
			departments(){
				return this.$store.getters.getDepartments;
			},
			
			users(){
				return this.$store.getters.getUsers;
			},
		},
		
		data(){
			return {
				messageEdit: null,
				messageNew: {
					text: null,
					departmentIds: [],
					usernames: [],
					published: true,
					type: 'everyone',
					author: {},
				},
				showMessageNew: false,
				loading: null,
			}
		},
		
		methods: {
		},
		
		async mounted(){
			this.loading = 'fetching';
			
			await Promise.allSettled([
				this.$store.dispatch('listDepartments'),
				this.$store.dispatch('listMessages'),
				this.$store.dispatch('listUsers'),
			]);
			
			this.loading = null;
		},
	}
</script>



<style lang="scss" scoped>
	table {
		thead {
			@apply bg-beige;
		}
		tbody {
			tr {
				&:hover {
					td {
						@apply bg-beige;
						@apply text-black;
						
					}
				}
			}
		}
	}
</style>